const translation = {
  knowledge: 'Knowledge Base',
  documentCount: ' Documents',
  videoCount: ' Videos',
  wordCount: 'Thousand Characters',
  appCount: ' Associated Chatbot',
  createDataset: 'Create Text Knowledge Base',
  createVideoDataset: 'Create Video Knowledge Base',
  createDatasetIntro: 'Import your own text data or write data in real-time via Webhook to enhance the context of the LLM.',
  deleteDatasetConfirmTitle: 'Delete Knowledge Base?',
  deleteDatasetConfirmContent:
    'Deleting the Knowledge is irreversible. Users will no longer be able to access your Knowledge, and all prompt configurations and logs will be permanently deleted.',
  datasetUsedByApp: 'The knowledge is being used by some apps. Apps will no longer be able to use this Knowledge, and all prompt configurations and logs will be permanently deleted.',
  datasetDeleted: 'Knowledge deleted',
  datasetDeleteFailed: 'Failed to delete Knowledge',
  didYouKnow: 'Did you know?',
  intro1: 'Knowledge Base can be integrated into the Dify App',
  intro2: 'as context',
  intro3: ',',
  intro4: 'or can be',
  intro5: 'created',
  intro6: 'published as a standalone ChatGPT plugin for use',
  unavailable: 'Unavailable',
  unavailableTip: 'Due to the embedding model being unavailable, please configure the default embedding model',
  datasets: 'Documents',
  videoDatasets: 'Videos',
  datasetsDisabledManage: 'This Chatbot did not successfully add the knowledge base, please add manually',
  datasetsApi: 'API',
  retrieval: {
    semantic_search: {
      title: 'Vector Retrieval',
      description: 'Generates query embeddings and retrieves text segments most similar to their vector representation',
    },
    full_text_search: {
      title: 'Full-text Search',
      description: 'Indexes all terms within documents, allowing users to query any term and return text segments containing those terms',
    },
    hybrid_search: {
      title: 'Hybrid Search',
      description: 'Execute full-text search and vector searches simultaneously, re-rank to select the best match for the user\'s query. Users can choose to set weights or configure to a Rerank model.',
      recommend: 'Recommend',
    },
    invertedIndex: {
      title: 'Inverted Index',
      description: 'An inverted index is a structure used for efficient retrieval. Organized by terms, each term points to the documents or web pages that contain it',
    },
    change: 'Change',
    changeRetrievalMethod: 'Change Retrieval Method',
  },
  docsFailedNotice: 'Document learning failed',
  videoFailedNotice: 'Video learning failed',
  webPageFailedNotice: 'Webpage learning failed',
  retry: 'Retry',
  indexingTechnique: {
    high_quality: 'HQ',
    economy: 'ECO',
  },
  indexingMethod: {
    semantic_search: 'VECTOR',
    full_text_search: 'FULL TEXT',
    hybrid_search: 'HYBRID',
    invertedIndex: 'INVERTED',
  },
  mixtureHighQualityAndEconomicTip: 'The Rerank model is required for mixture of high quality and economical knowledge bases.',
  inconsistentEmbeddingModelTip: 'The Rerank model is required if the Embedding models of the selected knowledge bases are inconsistent.',
  retrievalSettings: 'Retrieval Setting',
  rerankSettings: 'Rerank Setting',
  weightedScore: {
    title: 'Weighted Score',
    description: 'By adjusting the weights assigned, this rerank strategy determines whether to prioritize semantic or keyword matching.',
    semanticFirst: 'Semantic first',
    keywordFirst: 'Keyword first',
    customized: 'Customized',
    semantic: 'Semantic',
    keyword: 'Keyword',
  },
  nTo1RetrievalLegacy: 'N-to-1 retrieval will be officially deprecated from September. It is recommended to use the latest Multi-path retrieval to obtain better results. ',
  nTo1RetrievalLegacyLink: 'Learn more',
  nTo1RetrievalLegacyLinkText: ' N-to-1 retrieval will be officially deprecated in September.',
}

export default translation
