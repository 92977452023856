export enum ToMainEvent {
  CLOSE = 'close', // 关闭 widget
  ZENDESK_SERVICE_MESSAGE = 'zendesk-service-message',
  UPDATE_CHAT_LIST_SHOW_FEEDBACK = 'update-chat-list-show-feedback', // 控制聊天界面出现ai服务评价
  UPDATE_IFRAME_RECT = 'update_iframe_rect', // 动态更新chatbot入口的iframe高度宽度，减少对主页面其他区域的影响
  SEND_QUERY_TO_AI = 'send_query_to_ai', // 向embed_chatbot内发送提问
  AROUSE_BUBBLE_CONTENT = 'arouse_bubble_content', // 向embed.js发通知唤起气泡
  AROUSE_CHAT = 'arouse_chat', // 点击气泡框其他非按钮区域，向embed.js发通知唤起聊天框
  CHANGE_CONVERSATION_INFO = 'change_conversation_info',
  CHAT_READY = 'chat_ready',
  CLEAR_TRIGGER_RESULT = 'clear_trigger_result',
}

export const postMain = (event: ToMainEvent, data = {}) => {
  window.parent.postMessage({ event, data }, '*')
}

export enum GetMainDataType {
  SEND_CONFIG_DATA = 'send-config-data', // 将chatbot配置传到entrance iframe内，控制chatbot入口个性化配置&打招呼语等
  SHOW_SERVICE_FEEDBACK = 'show-service-feedback', // 移动端场景下点击顶部close按钮出现气泡邀评
  SHOW_ZENDESK_SERVICE_MESSAGE = 'show-zendesk-service-message', // 将zendesk发送来的消息展示出来
  LOAD_VIEWS_TRACKING = 'load-views-tracking', // umami统计数据
  JUDGE_SHOW_FEEDBACK = 'judge-show-feedback', // 点击logo收起聊天时弹出收评
  SHOW_FEEDBACK_IN_CHAT = 'show-feedback-in-chat', // 聊天页面显示ai服务评价
  SUGGESTION_QUESTION = 'suggestion-question', // chatbot 入口向chat发送问题
  CLOSE_BUBBLE = 'close-bubble',
  ACTIVE_MARKETING = 'active-marketing', // 主动营销
  MASTER_PAGE_DATA = 'master-page-data',
}
