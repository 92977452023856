const translation = {
  common: {
    workflowProcess: 'ИИ думает',
    unixyzAnswerLoading: {
      '6s': ['Я ищу информацию…', 'Позвольте мне получить детали…', 'Я найду это для вас…', 'Ищу это…'],
      '11s': ['Минутку, скоро закончим!', 'Почти готово, подождите немного!', 'Подождите немного, мы почти пришли!', 'Думаю, мы почти на финишной прямой!'],
      '21s': ['Это немного сложно, подождите немного.', 'Это немного сложно, дайте еще немного времени…', 'Много деталей, мне нужно немного больше времени…', 'Много информации, дайте мне немного времени, чтобы внимательно проверить.'],
      '31s': ['Что-то не так, но мы уже работаем над этим!'],
      'finish': 'Понял! ',
    },
  },
}

export default translation
